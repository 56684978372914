import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { LanguageProvider, LANGUAGES } from "./hooks/languageContextHooks";
import "./i18n";
import { HotelDataContextHook } from "./hooks/HotelDataContextHook";
import { BrowserRouter } from "react-router-dom";
import UbicationFilterContext from "./hooks/UbicationFilterContext";
import Fallback from "./pages/fallback/Fallback";
import {
  designI,
  unitTypeI,
} from "./redux/slices/enviromentSlice/EnviromentSlice.type";
import { startDefaultConfigs } from "./config";
import {
  createTheme,
  ScopedCssBaseline,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { StyleSheetManager } from "styled-components";
import {
  LocationDropdownDesign,
  UnitType,
  WidgetConfigProps,
  WidgetDesign,
} from "./types/widgetConfig.types";
import WidgetConfigContextProvider from "./contexts/widgetConfig/WidgetConfig.context";
import { setupStore } from "./redux/store";
import ShadowDomContextProvider from "./contexts/ShadowDom.context";
import { styleTags } from "./utils/style-loader";

declare global {
  interface Window {
    availRoomBookingApp: any;
  }
}

export interface WidgetProps {
  divId: string;
  environmentId: string;
  type: unitTypeI;
  unitId?: string;
  gmapToken?: string;
  defaultMargin?: boolean;
  design?: designI;
  hideRating?: boolean;
  hideLanguageSelector?: boolean;
  clientIdentifier?: string;
  paymentDefaultData?: WidgetPropsPaymentDefaultData;
  showSearchHotelInput?: boolean;
  languages: LANGUAGES[];
  shadowDom?: boolean;
  defaultLang?: LANGUAGES;
  locationDropdownDesign: LocationDropdownDesign;
}

export interface WidgetPropsPaymentDefaultData {
  name?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  disableInputs?: boolean;
}

export const isProd = process.env.NODE_ENV === "production";

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ` "Poppins","Roboto","-apple-system","BlinkMacSystemFont","Segoe UI","Helvetica Neue","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          fontFamily: [
            "Poppins",
            "Roboto",
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Helvetica Neue",
            "Arial",
            "sans-serif",
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol",
          ].join(","),
        },
      },
    },
  },
};

class availRoomBookingApp {
  constructor(params: WidgetConfigProps) {
    const { divId, environmentId, shadowDom, defaultLang, shadowStyles } =
      params;

    const searchParams = new URLSearchParams(window.location.search);
    const currentEnvironmentId = searchParams.has("environment")
      ? searchParams.get("environment")!
      : environmentId;

    startDefaultConfigs({
      environmentId: currentEnvironmentId,
    });

    const containerEl = document.getElementById(divId);

    if (!containerEl)
      throw new Error(`no container element with id ${divId} found`);

    // shadowDom

    let emotionRoot;

    if (shadowDom) {
      const shadowContainer =
        containerEl.shadowRoot ?? containerEl.attachShadow({ mode: "open" });
      emotionRoot = document.createElement("style");
      const shadowRootEl = document.createElement("div");

      const portalRootEl: HTMLDivElement =
        document.querySelector("#availroom-portal-root") ??
        document.createElement("div");
      // let portalSadowContainer = portalRootEl.shadowRoot ?? portalRootEl.attachShadow({ mode: "open" })

      const portalEmotionRoot = document.createElement("style");
      portalRootEl.setAttribute("id", "availroom-portal-root");
      const portalSadowContainer =
        portalRootEl.shadowRoot ?? portalRootEl.attachShadow({ mode: "open" });
      document.body.appendChild(portalRootEl);

      const portalRootContainerEl = document.createElement("div");
      portalRootContainerEl.setAttribute(
        "id",
        "availroom-portal-root-container-el"
      );
      const portalStyledComponentsContainerEl = document.createElement("div");

      //append shadow Styles

      // styled components shadow
      const styledComponentsContainerEl = document.createElement("div");

      if (shadowStyles) {
        const shadowStylesEl = document.createElement("style");
        shadowStylesEl.innerHTML = shadowStyles;
        shadowContainer.appendChild(shadowStylesEl);
      }

      // append style link
      const normalizeStyleLink = document.createElement("link");
      normalizeStyleLink.setAttribute(
        "href",
        isProd
          ? `${process.env.REACT_APP_HOSTNAME}/normalize.css`
          : "/normalize.css"
      );
      normalizeStyleLink.setAttribute("rel", "stylesheet");
      normalizeStyleLink.setAttribute("type", "text/css");
      shadowContainer.insertBefore(
        normalizeStyleLink.cloneNode(true),
        shadowContainer.firstChild
      );
      portalSadowContainer.insertBefore(
        normalizeStyleLink.cloneNode(true),
        portalSadowContainer.firstChild
      );

      if (isProd) {
        const styleLink = document.createElement("link");
        styleLink.setAttribute(
          "href",
          isProd
            ? `${process.env.REACT_APP_HOSTNAME}/static/css/main.css`
            : "/static/css/main.css"
        );
        styleLink.setAttribute("rel", "stylesheet");
        styleLink.setAttribute("type", "text/css");
        shadowContainer.insertBefore(
          styleLink.cloneNode(true),
          shadowContainer.firstChild
        );
        portalSadowContainer.insertBefore(
          styleLink.cloneNode(true),
          portalSadowContainer.firstChild
        );
      }

      containerEl.setAttribute("data-shadowed", "style-isolated-shadowed");
      portalRootEl.setAttribute("data-shadowed", "style-isolated-shadowed");

      // shadowContainer.append(...styleTags);
      shadowContainer.appendChild(emotionRoot);
      shadowContainer.appendChild(styledComponentsContainerEl);
      shadowContainer.appendChild(shadowRootEl);

      // portalSadowContainer.append(...styleTags);
      portalSadowContainer.appendChild(portalRootContainerEl);
      portalSadowContainer.appendChild(portalEmotionRoot);
      portalSadowContainer.appendChild(portalStyledComponentsContainerEl);

      // import fonts
      const fontLinkEl = document.createElement("link");
      fontLinkEl.setAttribute(
        "href",
        "https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap"
      );
      fontLinkEl.setAttribute("rel", "stylesheet");
      fontLinkEl.setAttribute("type", "text/css");

      shadowContainer.appendChild(fontLinkEl);
      portalSadowContainer.appendChild(fontLinkEl);

      const cache = createCache({
        key: "css",
        prepend: true,
        container: emotionRoot,
      });

      const root = ReactDOM.createRoot(shadowRootEl);

      const muiTheme = createTheme({
        ...themeOptions,
        components: {
          ...themeOptions.components,
          MuiPopover: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
          MuiPopper: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
          MuiModal: {
            defaultProps: {
              container: shadowRootEl,
            },
          },
        },
      });

      // console.log(styleTags);

      document.head.append(...styleTags);

      root.render(
        <CacheProvider value={cache}>
          <ThemeProvider theme={muiTheme}>
            <ScopedCssBaseline>
              <StyleSheetManager target={styledComponentsContainerEl}>
                <ShadowDomContextProvider
                  portalRootContainerEl={portalRootContainerEl}
                  portalRootEl={portalRootEl}
                  shadowRoot={shadowContainer}
                  portalShadowRoot={portalSadowContainer}
                >
                  <AppProviders widgetConfig={params}>
                    <App
                      // className={resetStyles.availroomAppContainerReset}
                      {...params}
                    />
                  </AppProviders>
                </ShadowDomContextProvider>
              </StyleSheetManager>
            </ScopedCssBaseline>
          </ThemeProvider>
        </CacheProvider>
      );
    } else {
      document.head.append(...styleTags);
      const root = ReactDOM.createRoot(containerEl);
      const muiTheme = createTheme(themeOptions);

      root.render(
        <ThemeProvider theme={muiTheme}>
          <ScopedCssBaseline>
            <AppProviders widgetConfig={params}>
              <App {...params} />
            </AppProviders>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    }

    const event = new CustomEvent("ReactDOMReady", {
      detail: { message: "React component is now rendered!" },
      bubbles: true,
      cancelable: false,
    });
    window.dispatchEvent(event);

    return this;
  }
}

export default availRoomBookingApp;

reportWebVitals();

const AppProviders: React.FC<{
  children: React.ReactNode;
  widgetConfig: WidgetConfigProps;
}> = ({ children, widgetConfig }) => {
  const store = setupStore();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Fallback />}>
          <WidgetConfigContextProvider widgetConfig={widgetConfig}>
            <LanguageProvider
              defaultLanguage={widgetConfig.defaultLang ?? LANGUAGES.ES}
            >
              <UbicationFilterContext>
                <HotelDataContextHook>{children}</HotelDataContextHook>
              </UbicationFilterContext>
            </LanguageProvider>
          </WidgetConfigContextProvider>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};

const renderApp = () => {
  new availRoomBookingApp({
    divId: "availRoomBookingApp",
    environmentId: "ACDACDBF26D14E1E948D56AA66730E1A",
    gmapToken: process.env.REACT_APP_GOOGLE_MAPS_API!,
    shadowDom: true,
    type: UnitType.MULTIUNIT,
    defaultMargin: true,
    design: WidgetDesign.DEFAULT,
    // depositPopupOnAccessPropertyPage: true,
    // design: "headless",
    // showSearchHotelInput: true,
    locationDropdownDesign: LocationDropdownDesign.NESTED_DROPDOWN,
    languages: [LANGUAGES.ES, LANGUAGES.EN_US],
    // defaultNights: 1,
    // paymentDefaultData: {
    //   // name: "Prueba",
    //   lastname: "Prueba",
    //   email: "prueba@prueba.com",
    //   phone: "000000000"
    //   // disableInputs: true
    // }
    // hideLanguageSelector: true
    // hideRating: true
  });
};

if (!isProd) {
  renderApp();
}

// if (!isProd && module.hot) {
//   renderApp();

//   if (module.hot) {
//     module.hot?.accept("./App", renderApp);
//     console.log("hot reloading");
//   }
// }
