import { startAxiosRetry } from "./axiosRetryConfig";
import { startMomentConfig } from "./momentConfig";
import { startSentryConfig } from "./sentryConfig";

export interface startDefaultConfigsParams {
  environmentId: string;
}

export const startDefaultConfigs = ({
  environmentId,
}: startDefaultConfigsParams) => {
  startMomentConfig();
  startSentryConfig(environmentId);
  startAxiosRetry();
};
