import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";

export const startAxiosRetry = () => {
  axiosRetry(axios, {
    retries: 3,
    retryCondition: (error) => {
      if (!error.response || isNetworkOrIdempotentRequestError(error)) {
        return true;
      }
      return false;
    },
    retryDelay: () => 500,
    shouldResetTimeout: true
  });
};
