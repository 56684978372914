import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers";
import { cartI } from "./slices/cartSlice/CartSlice.type";
import { environmentI } from "./slices/enviromentSlice/EnviromentSlice.type";
import { propertyI } from "./slices/propertySlice/PropertySlice.type";

export interface State {
  environment: environmentI;
  property: propertyI;
  cart: cartI;
}

// const store = configureStore({ reducer });

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
      }),
    preloadedState,
  });

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot?.accept("./reducers", () => {
      return store.replaceReducer(rootReducer);
    });
  }

  return store;
};

// export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
