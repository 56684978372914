import { createContext, useContext, useRef, useState } from "react";

interface ShadowmDomContextProps {
  portalRootContainerEl?: HTMLDivElement;
  portalRootEl?: HTMLDivElement;
  shadowRoot?: ShadowRoot;
  portalShadowRoot?: ShadowRoot;
  isStylesLoaded: boolean;
  setLoadedStyles: () => void;
}

const ShadowDomContext = createContext<ShadowmDomContextProps>(
  {} as ShadowmDomContextProps
);

const ShadowDomContextProvider = ({
  children,
  ...props
}: {
  children?: JSX.Element;
  portalRootContainerEl?: HTMLDivElement;
  portalRootEl?: HTMLDivElement;
  shadowRoot?: ShadowRoot;
  portalShadowRoot?: ShadowRoot;
}) => {
  const [stylesLoaded, setStylesLoaded] = useState(false);

  const setLoadedStyles = () => {
    setStylesLoaded(true);
  };

  return (
    <ShadowDomContext.Provider
      value={{
        ...props,
        isStylesLoaded: stylesLoaded,
        setLoadedStyles,
      }}
    >
      {children}
    </ShadowDomContext.Provider>
  );
};
export default ShadowDomContextProvider;
export const useShadowDomContext = () => useContext(ShadowDomContext);
