import React, { useContext, useState } from "react";
import { createContext } from "react";
import { getBookingEngineAvailability } from "../api/getBookingEngineAvailability/getBookingEngineAvailability";
import { selectChannelId } from "../redux/slices/enviromentSlice/EnviromentSlice.selectors";
import {
  getDataAvailabilityPostJson,
  HotelDataListI,
} from "../redux/slices/enviromentSlice/EnviromentSlice.type";
import { State } from "../redux/store";
import { useAppSelector } from "./reduxHooks";

interface environmentDataI {
  iniDate?: string;
  endDate?: string;
  serverPath?: string;
  enviromentId?: string[];
  userLang?: string;
  zoneId?: string;
  adults?: number;
  childrenAges?: number[];
  couponCode?: string;
}
interface contextI {
  filteredHotelDataList: HotelDataListI[];
  hotelDataList: HotelDataListI[];
  fetchHotelDataList: (
    enviromentData?: environmentDataI
  ) => Promise<void | HotelDataListI[]>;
  setFilteredHotelDataList: React.Dispatch<HotelDataListI[]>;
  loading: boolean;
  nights: string;
}

const hotelDataContext = createContext({} as contextI);

export const HotelDataContextHook: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  // redux hooks
  const channelId = useAppSelector(selectChannelId);

  const [filteredHotelDataList, setFilteredHotelDataList] = useState<
    HotelDataListI[]
  >([]);
  const [hotelDataList, setHotelDataList] = useState<HotelDataListI[]>([]);
  const [loading, setLoading] = useState(false);
  const [nights, setNights] = useState<string>("");

  const {
    adults,
    userLang,
    iniDate,
    endDate,
    childrenAgeList,
    environmentDataList,
    serverPath,
    zoneId,
    tourist,
    roomtype,
    searchHotel,
  } = useAppSelector((state: State) => state.environment);

  const fetchHotelDataList = async (enviromentData?: environmentDataI) => {
    try {
      const getAvailabilityPostJson: getDataAvailabilityPostJson = {
        adults: enviromentData?.adults?.toString() ?? adults.toString(),
        environmentDataList:
          enviromentData?.enviromentId ?? environmentDataList,
        userLang: enviromentData?.userLang ?? userLang,
        iniDate: enviromentData?.iniDate ?? iniDate,
        endDate: enviromentData?.endDate ?? endDate,
        childrenAgeList:
          enviromentData?.childrenAges?.map((age) => age.toString()) ??
          childrenAgeList.map((age) => age.toString()),
        showMessageRequest: true,
        couponCode: enviromentData?.couponCode,
        channelId,
        ...(tourist !== undefined && { tourist }),
        ...(roomtype && {
          roomTypeList: [roomtype],
        }),
      };
      setLoading(true);
      const newHotelDataResponse = await getBookingEngineAvailability({
        serverPath: enviromentData?.serverPath ?? serverPath,
        data: getAvailabilityPostJson,
      });
      const hotelResData: {
        clientDataList: Array<{
          fromDate: string;
          id: string;
          nights: string;
          showChildren: boolean;
          showInfants: boolean;
          showTagsInSearch: boolean;
          hotelDataList: HotelDataListI[];
        }>;
      } = newHotelDataResponse.data;

      setNights(hotelResData.clientDataList[0].nights);

      let allHotelDataList = hotelResData.clientDataList.reduce(
        (acum, currentData) => {
          return (acum = [...acum, ...currentData.hotelDataList]);
        },
        [] as HotelDataListI[]
      );

      setHotelDataList(allHotelDataList);

      const zoneIdToFilter = enviromentData?.zoneId ?? zoneId;

      if (zoneIdToFilter || searchHotel) {
        allHotelDataList = allHotelDataList.filter((hotelData) => {
          if (searchHotel) {
            if (hotelData.id === searchHotel) return true;
            return false;
          }

          if (
            zoneIdToFilter &&
            !hotelData.zoneStringList?.some(
              (zoneString) => zoneString === zoneIdToFilter
            )
          ) {
            return false;
          }
          return true;
        });
      }

      setFilteredHotelDataList(allHotelDataList);
      setLoading(false);
      return allHotelDataList;
    } catch (error) {
      throw error;
    }
  };

  return (
    <hotelDataContext.Provider
      value={{
        filteredHotelDataList,
        hotelDataList,
        setFilteredHotelDataList: setFilteredHotelDataList,
        fetchHotelDataList,
        nights,
        loading,
      }}
    >
      {props.children}
    </hotelDataContext.Provider>
  );
};

export const useHotelDataContext = () => useContext(hotelDataContext);
