import { init as SentryInit, Integrations, setTag } from "@sentry/react";
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from "@sentry/integrations";

export const startSentryConfig = (environmentId: string) => {
  const isAvailroomDomain = window.location.hostname.includes("availroom");

  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.GlobalHandlers({
        onerror: false,
        onunhandledrejection: false,
      }),
      new ExtraErrorDataIntegration() as any,
      ...(isAvailroomDomain
        ? [
            new CaptureConsoleIntegration({
              levels: ["error"],
            }) as any,
          ]
        : []),
    ],
    defaultIntegrations: isAvailroomDomain ? undefined : false,
    release: process.env.REACT_APP_RELEASE,
    beforeSend: (event) => {
      event.fingerprint = [environmentId];
      return event;
    },
  });

  setTag("domain", window.location.hostname);
  const environment = new URLSearchParams(window.location.search).get(
    "environment"
  );
  setTag("environmentId", environment ?? environmentId);
};
