import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { APP_PAGES } from "../App";
import { setEnviroment } from "../redux/slices/enviromentSlice/EnviromentSlice";
import { State } from "../redux/store";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import useQuery from "./useQuery";
import { QUERY_PARAMS } from "./useQueryParams";

const UpdateUrlParams = () => {
  const dispatch = useAppDispatch();
  const { adults, userLang, iniDate, endDate, childrenAgeList, zoneId } =
    useAppSelector((state: State) => state.environment);

  const history = useHistory();

  const queryParams = useQuery();

  const updateUrlParams = useCallback(
    (params: {
      updateAdults?: number;
      updateIniDate?: string;
      updateEndDate?: string;
      updateChildrenAgeList?: number[];
      updateZoneId?: string;
      updateAll?: boolean;
      updatePage?: string;
      replace?: boolean;
      updateLanguage?: string;
      removeAges?: boolean;
      updateSearchHotel?: string;
      page?: number;
    }) => {
      const {
        updateAdults,
        updateIniDate,
        updateEndDate,
        updateChildrenAgeList,
        updateZoneId,
        updateAll,
        updatePage,
        replace,
        updateLanguage,
        removeAges,
        updateSearchHotel,
        page,
      } = params;
      let queryArray: { id: string; value: string }[] = [];

      if (history.location.search) {
        queryArray = history.location.search.split("&").map((query) => {
          let currentQuery = query;
          if (query.startsWith("?")) {
            currentQuery = currentQuery.substring(1);
          }
          const a = currentQuery.split("=");
          return { id: a[0], value: a[1] };
        });
      }

      if (updateSearchHotel) {
        const existingQueryIndex = queryArray.findIndex(
          (query) => query.id === "searchHotel"
        );
        if (existingQueryIndex !== -1) {
          queryArray[existingQueryIndex] = {
            ...queryArray[existingQueryIndex],
            value: updateSearchHotel,
          };
        } else {
          queryArray.push({
            id: "searchHotel",
            value: updateSearchHotel,
          });
        }
      } else {
        queryArray = queryArray.filter(({ id }) => id !== "searchHotel");
      }

      if ((adults && updateAdults) || (adults && updateAll)) {
        const AdultsQueryId = queryArray.findIndex(
          (query) => query.id === "adults"
        );
        if (AdultsQueryId !== -1) {
          const newQueryArray = [...queryArray];
          const newAdultsValue = { ...newQueryArray[AdultsQueryId] };
          newAdultsValue.value = updateAdults?.toString() ?? adults.toString();
          newQueryArray[AdultsQueryId] = newAdultsValue;
          queryArray = newQueryArray;
        } else {
          queryArray = [
            ...queryArray,
            {
              id: "adults",
              value: updateAdults?.toString() ?? adults?.toString(),
            },
          ];
        }
      }

      if ((iniDate && updateIniDate) || (iniDate && updateAll)) {
        const iniDateQueryId = queryArray.findIndex(
          (query) => query.id === "iniDate"
        );
        if (iniDateQueryId !== -1) {
          const newQueryArray = [...queryArray];
          const newQueryArrayValue = { ...newQueryArray[iniDateQueryId] };
          newQueryArrayValue.value = updateIniDate ?? iniDate;
          newQueryArray[iniDateQueryId] = newQueryArrayValue;
          queryArray = newQueryArray;
        } else {
          queryArray = [
            ...queryArray,
            {
              id: "iniDate",
              value: updateIniDate ?? iniDate,
            },
          ];
        }
      }

      if ((endDate && updateEndDate) || (endDate && updateAll)) {
        const endDateQueryId = queryArray.findIndex(
          (query) => query.id === "endDate"
        );
        if (endDateQueryId !== -1) {
          const newQueryArray = [...queryArray];
          const newQueryArrayValue = { ...newQueryArray[endDateQueryId] };
          newQueryArrayValue.value = updateEndDate ?? endDate;
          newQueryArray[endDateQueryId] = newQueryArrayValue;
          queryArray = newQueryArray;
        } else {
          queryArray = [
            ...queryArray,
            {
              id: "endDate",
              value: updateEndDate ?? endDate,
            },
          ];
        }
      }

      if ((userLang && updateLanguage) || (userLang && updateAll)) {
        const languageQueryId = queryArray.findIndex(
          (query) => query.id === "language"
        );
        if (languageQueryId !== -1) {
          const newQueryArray = [...queryArray];
          const newQueryArrayValue = { ...newQueryArray[languageQueryId] };
          newQueryArrayValue.value = updateLanguage ?? userLang;
          newQueryArray[languageQueryId] = newQueryArrayValue;
          queryArray = newQueryArray;
        } else {
          queryArray = [
            ...queryArray,
            {
              id: "language",
              value: updateLanguage ?? userLang,
            },
          ];
        }
      }

      if (
        (childrenAgeList && updateChildrenAgeList) ||
        (childrenAgeList && updateAll)
      ) {
        const childrenAgeListQueryId = queryArray.findIndex(
          (query) => query.id === "childrenAges"
        );
        if (
          childrenAgeList.length > 0 ||
          (updateChildrenAgeList && updateChildrenAgeList.length > 0)
        ) {
          if (childrenAgeListQueryId !== -1) {
            const newQueryArray = [...queryArray];
            const newQueryArrayValue = {
              ...newQueryArray[childrenAgeListQueryId],
            };
            newQueryArrayValue.value =
              updateChildrenAgeList?.join(",") ?? childrenAgeList.join(",");
            newQueryArray[childrenAgeListQueryId] = newQueryArrayValue;
            queryArray = newQueryArray;
          } else {
            queryArray = [
              ...queryArray,
              {
                id: "childrenAges",
                value:
                  updateChildrenAgeList?.join(",") ?? childrenAgeList.join(","),
              },
            ];
          }
        } else {
          if (childrenAgeListQueryId !== -1) {
            queryArray = queryArray.filter(
              (query) => query.id !== "childrenAges"
            );
          }
        }
      }

      if (
        (zoneId !== undefined && updateZoneId !== undefined) ||
        (zoneId !== undefined && updateAll)
      ) {
        const newZoneId = updateZoneId ?? zoneId;
        const zoneIdQueryId = queryArray.findIndex(
          (query) => query.id === "zoneId"
        );
        if (newZoneId) {
          if (zoneIdQueryId !== -1) {
            const newQueryArray = [...queryArray];
            const newQueryArrayValue = { ...newQueryArray[zoneIdQueryId] };
            newQueryArrayValue.value = updateZoneId ?? zoneId;
            newQueryArray[zoneIdQueryId] = newQueryArrayValue;
            queryArray = newQueryArray;
          } else {
            queryArray = [
              ...queryArray,
              {
                id: "zoneId",
                value: newZoneId,
              },
            ];
          }
        } else {
          queryArray = queryArray.filter((query) => query.id !== "zoneId");
        }
      }

      if (updatePage || updateAll) {
        const selectedPage = queryParams.get("approute");
        const pageQueryId = queryArray.findIndex(
          (query) => query.id === "approute"
        );
        if (pageQueryId !== -1) {
          const newQueryArray = [...queryArray];
          const newQueryArrayValue = { ...newQueryArray[pageQueryId] };
          newQueryArrayValue.value =
            updatePage ?? selectedPage ?? APP_PAGES.HOME;
          newQueryArray[pageQueryId] = newQueryArrayValue;
          queryArray = newQueryArray;
        } else {
          queryArray = [
            ...queryArray,
            {
              id: "approute",
              value: updatePage ?? selectedPage ?? APP_PAGES.HOME,
            },
          ];
        }
      }

      if (removeAges) {
        queryArray = queryArray.filter((query) => query.id !== "age");
      }

      if (page !== undefined) {
        const currentQuery = queryArray.findIndex(
          ({ id }) => id === QUERY_PARAMS.HOME_PAGE
        );

        const newQuery = {
          id: QUERY_PARAMS.HOME_PAGE,
          value: page.toString(),
        };

        if (currentQuery === -1) {
          queryArray.push(newQuery);
        } else {
          const newQueryArray = [
            ...queryArray.slice(0, currentQuery),
            newQuery,
            ...queryArray.slice(currentQuery + 1),
          ];
          queryArray = newQueryArray;
        }
      }

      const newParams = queryArray.reduce(
        (acum, currentValue, currentIndex) => {
          acum += `${currentIndex === 0 ? "?" : "&"}${currentValue.id}=${
            currentValue.value
          }`;
          return acum;
        },
        ""
      );

      if (replace) return history.replace(newParams);
      history.push(newParams);
    },
    [
      adults,
      childrenAgeList,
      endDate,
      history,
      iniDate,
      queryParams,
      userLang,
      zoneId,
    ]
  );

  const updateEnvironment = () => {
    const urlQueryParamsData = history.location.search
      .split("&")
      .map((query) => {
        let currentQuery = query;
        if (query.startsWith("?")) {
          currentQuery = currentQuery.substring(1);
        }
        const a = currentQuery.split("=");
        return { id: a[0], value: a[1] };
      });
    const object = urlQueryParamsData.reduce((acum, object) => {
      acum = { ...acum, [object.id]: object.value };
      return acum;
    }, {});
    dispatch(setEnviroment({ ...object }));
    return object;
  };
  return { updateUrlParams, updateEnvironment };
};

export default UpdateUrlParams;
