import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import { LANGUAGES } from "./hooks/languageContextHooks";

import deResource from "./locales/de/translation.json";
import enResource from "./locales/en/translation.json";
import esResource from "./locales/es/translation.json";
import frResource from "./locales/fr/translation.json";
import itResource from "./locales/it/translation.json";
import nlResource from "./locales/nl/translation.json";
import noResource from "./locales/no/translation.json";
import plResource from "./locales/pl/translation.json";
import ptResource from "./locales/pt/translation.json";
import ruResource from "./locales/ru/translation.json";

// import HttpApi from "i18next-http-backend";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const isProd = process.env.NODE_ENV === "production";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: LANGUAGES.ES,
    fallbackLng: LANGUAGES.ES,
    debug: true,
    // backend: {
    //   loadPath: function () {
    //     return "https://booking.availroom.com/v2/locales/{{lng}}/{{ns}}.json";
    //     // return isProd
    //     //   ? "https://booking.availroom.com/v2/locales/{{lng}}/{{ns}}.json"
    //     //   : "/locales/{{lng}}/{{ns}}.json";
    //   },
    //   // addPath: "https://booking.availroom.com/v2/locales/{{lng}}/{{ns}}.json",
    //   crossDomain: true,
    // },
    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
  });

i18n.addResourceBundle("de", "translation", deResource, true, true);
i18n.addResourceBundle("en", "translation", enResource, true, true);
i18n.addResourceBundle("es", "translation", esResource, true, true);
i18n.addResourceBundle("fr", "translation", frResource, true, true);
i18n.addResourceBundle("it", "translation", itResource, true, true);
i18n.addResourceBundle("nl", "translation", nlResource, true, true);
i18n.addResourceBundle("no", "translation", noResource, true, true);
i18n.addResourceBundle("pl", "translation", plResource, true, true);
i18n.addResourceBundle("pt", "translation", ptResource, true, true);
i18n.addResourceBundle("ru", "translation", ruResource, true, true);

export default i18n;
