import { combineReducers } from "@reduxjs/toolkit";
import { cartSlice } from "./slices/cartSlice/CartSlice";
import { environmentSlice } from "./slices/enviromentSlice/EnviromentSlice";
import { propertySlice } from "./slices/propertySlice/PropertySlice";

export const rootReducer = combineReducers({
  environment: environmentSlice.reducer,
  property: propertySlice.reducer,
  cart: cartSlice.reducer,
});
