import React from "react";
import CustomSpinner from "../../components/customSpinner/CustomSpinner";

import styles from "./Fallback.module.scss";
const Fallback = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.fallback}>
        <CustomSpinner />
      </div>
    </div>
  );
};

export default Fallback;
