import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Box, createTheme, ThemeProvider, useTheme } from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { StyleSheetManager } from "styled-components";
import { isProd, themeOptions } from ".";
import { useShadowDomContext } from "./contexts/ShadowDom.context";
import styles from "./App.module.scss";

const ShadowPortal = ({ children }: any) => {
  const node = useRef();
  const theme = useTheme();
  const { portalRootContainerEl, portalRootEl, portalShadowRoot, shadowRoot } =
    useShadowDomContext();

  useLayoutEffect(() => {
    const { current } = node;
    if (current && portalRootEl) {
      portalRootEl.appendChild(current);
    }
  }, [node, portalRootEl]);

  useEffect(() => {
    if (portalShadowRoot && shadowRoot && !isProd) {
      const stylesToRemove = portalShadowRoot.querySelectorAll(
        "style[availroom-app-styles]"
      );
      stylesToRemove.forEach((style) => {
        portalShadowRoot.removeChild(style);
      });
      const styles = shadowRoot.querySelectorAll("style[availroom-app-styles]");
      styles.forEach((style) => {
        const clonedStyle = style.cloneNode(true) as HTMLStyleElement;
        portalShadowRoot.insertBefore(clonedStyle, portalShadowRoot.firstChild);
      });
    }
  }, [shadowRoot]);

  const cache = useMemo(
    () =>
      createCache({
        key: "css",
        prepend: true,
        container: portalRootEl?.shadowRoot ?? undefined,
      }),
    []
  );

  const muiTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        ...themeOptions,
        components: {
          ...theme.components,
          ...themeOptions.components,
          MuiPopover: {
            defaultProps: {
              container: portalRootContainerEl,
            },
          },
          MuiPopper: {
            defaultProps: {
              container: portalRootContainerEl,
            },
          },
          MuiModal: {
            defaultProps: {
              container: portalRootContainerEl,
            },
          },
        },
      }),
    []
  );

  if (!portalRootEl) return <>{children}</>;

  return createPortal(
    <CacheProvider value={cache}>
      <StyleSheetManager target={portalRootEl.shadowRoot!}>
        <ThemeProvider theme={muiTheme}>
          <Box className={`${styles.App}`}>{children}</Box>
        </ThemeProvider>
      </StyleSheetManager>
    </CacheProvider>,
    portalRootEl.shadowRoot as any
  );
};

export default ShadowPortal;
