import { v4 } from "uuid";
import axios from "axios";
import { zoneI } from "../../hooks/UbicationFilterContext";

export interface apiGetFindZoneOrTagPostData {
  environmentDataId: string;
}

export interface apiGetFindZoneOrTagParams {
  serverPath: string;
  data: apiGetFindZoneOrTagPostData;
}

export interface apiGetFindZoneOrTagResponse {
  ok: boolean;
  zoneDataList: zoneI[];
  hotelDataList?: zoneI[];
}

// axiosRetry(axios, {
//   retries: 3,
//   retryCondition: () => true,
// });

export const apiGetFindZoneOrTag = ({
  serverPath,
  data,
}: apiGetFindZoneOrTagParams) => {
  return axios.post<apiGetFindZoneOrTagResponse>(
    `https://${serverPath}/sialdPMS/web/resourcescrossorigin/webcrossorigin/getfindzoneortag_api`,
    {
      ...data,
      requestId: v4(),
    }
  );
};
