import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_ADULTS } from "../../../defaultVars";
import { RoomTypeDataListI } from "../../../pages/property/property.type";
import { HotelDataListI } from "../enviromentSlice/EnviromentSlice.type";
import { propertyI } from "./PropertySlice.type";

const initialState: propertyI = {
  hotelData: {
    id: "",
    name: "",
    hotelDescription: "",
    latitude: "",
    longitude: "",
    roomTypeDataList: [],
    imagePathList: [],
    available: false,
    priceRefundable: "",
    priceNoOfferRefundable: "",
    priceNotRefundable: "",
    priceNoOfferNotRefundable: "",
    unitsFree: "",
    hotelType: "",
    address: "",
    amenitiesStringList: [],
    email: "",
    hotelLongDescription: "",
    numberOpinionReviews: "",
    opinionReview: "",
    phone: "",
    reviewList: [],
    stars: "",
    whatsApp: "",
    paidTypeList: []
  },
  iniDate: "",
  endDate: "",
  adults: DEFAULT_ADULTS,
  childrenAgeList: [],
  loading: false,
  error: ""
};

export const propertySlice = createSlice({
  initialState: initialState,
  name: "property",
  reducers: {
    setHotelData: (state, { payload }: PayloadAction<HotelDataListI>) => {
      state.hotelData = payload;
    },
    setPropertyIniDate: (state, { payload }: PayloadAction<string>) => {
      state.iniDate = payload;
    },
    setPropertyEndDate: (state, { payload }: PayloadAction<string>) => {
      state.endDate = payload;
    },
    setAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    setChildrenAgeList: (state, { payload }: PayloadAction<number[]>) => {
      state.childrenAgeList = payload;
    },
    setPropertyLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setPropertyError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setCouponCodeValid: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.hotelData.couponCodeValid = payload;
    },
    setCouponPercentDiscount: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.hotelData.couponPercentDiscount = payload;
    },
    setCouponQuantityDiscount: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.hotelData.couponQuantityDiscount = payload;
    },
    setNewRoomTypeData: (
      state,
      { payload }: PayloadAction<RoomTypeDataListI>
    ) => {
      const roomTypeDataIdToChange = state.hotelData.roomTypeDataList.findIndex(
        (roomTypeData) => roomTypeData.id === payload.id
      );
      state.hotelData.roomTypeDataList[roomTypeDataIdToChange] = payload;
    }
  }
});

export const {
  setHotelData,
  setPropertyEndDate,
  setPropertyIniDate,
  setAdults,
  setChildrenAgeList,
  setPropertyLoading,
  setNewRoomTypeData,
  setPropertyError,
  setCouponCodeValid,
  setCouponPercentDiscount,
  setCouponQuantityDiscount
} = propertySlice.actions;
