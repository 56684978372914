import i18next from "i18next";
import moment from "moment";
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useWidgetConfigContext } from "src/contexts/widgetConfig/WidgetConfig.context";
import { selectLang } from "../redux/slices/enviromentSlice/EnviromentSlice.selectors";
import { useAppSelector } from "./reduxHooks";
import useQuery from "./useQuery";

interface propsI {
  defaultLanguage?: LANGUAGES;
  children: React.ReactNode;
}

interface contextI {
  language: LANGUAGES;
  setLanguage: React.Dispatch<React.SetStateAction<LANGUAGES>>;
  enabledLanguages: {
    [key: string]: LANGUAGES;
  };
}

// export const ES = "es";
// export const EN = "en";

export enum LANGUAGES {
  ES = "es",
  EN_US = "en_us",
  EN_UK = "en_uk",
  DE = "de",
  FR = "fr",
  IT = "it",
  NL = "nl",
  NO = "no",
  PL = "pl",
  PT = "pt",
  RU = "ru",
}

const LANGUAGES_ISO_MAP: { [key in LANGUAGES]: string } = {
  [LANGUAGES.ES]: "es",
  [LANGUAGES.EN_UK]: "en",
  [LANGUAGES.EN_US]: "en",
  [LANGUAGES.DE]: "de",
  [LANGUAGES.FR]: "fr",
  [LANGUAGES.IT]: "it",
  [LANGUAGES.NL]: "nl",
  [LANGUAGES.NO]: "no",
  [LANGUAGES.PL]: "pl",
  [LANGUAGES.PT]: "pt",
  [LANGUAGES.RU]: "ru",
};

const MOMENT_LOCALE_MAP: { [key in LANGUAGES]?: string } = {
  [LANGUAGES.ES]: "es",
  [LANGUAGES.EN_UK]: "en-gb",
  [LANGUAGES.EN_US]: "en",
  [LANGUAGES.DE]: "de",
  [LANGUAGES.FR]: "fr",
  [LANGUAGES.IT]: "it",
  [LANGUAGES.NL]: "nl",
  [LANGUAGES.NO]: "nb",
  [LANGUAGES.PL]: "pl",
  [LANGUAGES.PT]: "pt",
  [LANGUAGES.RU]: "ru",
};

const LanguageContext = createContext({} as contextI);

export const LanguageProvider: React.FC<propsI> = (props) => {
  const { languages: appLanguages } = useWidgetConfigContext();
  const languageSelected = useAppSelector(selectLang) as LANGUAGES;
  const urlQueryLanguage = useQuery().get("language");
  const { children, defaultLanguage = LANGUAGES.ES } = props;
  const [language, setLanguage] = useState<LANGUAGES>(
    defaultLanguage || languageSelected || LANGUAGES.ES
  );

  const handleLanguageChange = useCallback(async () => {
    if (
      urlQueryLanguage &&
      Object.values(LANGUAGES).includes(urlQueryLanguage as any)
    ) {
      await changeLanguage(urlQueryLanguage as LANGUAGES);
      setLanguage(urlQueryLanguage as LANGUAGES);
      return;
    }
    await changeLanguage(defaultLanguage);
    setLanguage(defaultLanguage);
  }, [urlQueryLanguage, defaultLanguage]);

  useEffect(() => {
    handleLanguageChange();
  }, [languageSelected, urlQueryLanguage, handleLanguageChange, language]);

  const enabledLanguages = Object.entries(LANGUAGES).reduce(
    (acc, [key, val]) => {
      if (!appLanguages.includes(val)) return acc;
      acc = { ...acc, [key]: val };
      return acc;
    },
    {} as { [key: string]: LANGUAGES }
  );

  const changeLanguage = async (language: LANGUAGES) => {
    const momentIso = MOMENT_LOCALE_MAP[language];
    if (momentIso && momentIso !== "en")
      await import(`moment/locale/${momentIso}`);
    moment.locale(momentIso);
    const iso = MOMENT_LOCALE_MAP[language];
    return i18next.changeLanguage(iso ?? "en");
  };

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, enabledLanguages }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
