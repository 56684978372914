import axios from "axios";
import { v4 } from "uuid";
import { ClientDataListI } from "../../pages/property/property.type";
import { getDataAvailabilityPostJson } from "./../../redux/slices/enviromentSlice/EnviromentSlice.type.d";
export interface GetBookingEngineAvailabilityParams
  extends getDataAvailabilityPostJson {}

export interface GetBookingEngineAvailabilityResponse {
  clientDataList: ClientDataListI[];
}

// axiosRetry(axios, {
//   retries: 3,
//   retryCondition: () => true,
// });

export const getBookingEngineAvailability = async ({
  data,
  serverPath
}: {
  data: GetBookingEngineAvailabilityParams;
  serverPath: string;
}) => {
  const postData: Partial<getDataAvailabilityPostJson> = { ...data };
  const response = await axios.post<GetBookingEngineAvailabilityResponse>(
    `https://${serverPath}/sialdPMS/web/resourcescrossorigin/webcrossorigin/getbookingengineavailability_api`,
    {
      ...postData,
      requestId: v4(),
    }
  );
  return response;
};
