import React from "react";
import useQuery from "./useQuery";

export enum QUERY_PARAMS {
  HOME_PAGE = "homePage",
  PRICE_RANGE = "queryPriceRange",
}

export type QUERY_PARAM = { [key in keyof typeof QUERY_PARAMS]: string | null };

export const useQueryParams = () => {
  const query = useQuery();

  const pageQuery = query.get(QUERY_PARAMS.HOME_PAGE);

  const queryPriceRange = query.get(QUERY_PARAMS.PRICE_RANGE) ?? undefined;

  const priceRange = queryPriceRange && JSON.parse(queryPriceRange);

  const setQueryParams = ({
    homePage,
  }: {
    [QUERY_PARAMS.HOME_PAGE]: number | null;
    [QUERY_PARAMS.PRICE_RANGE]: [number, number] | null;
  }) => {
    const newQueryParams = new URLSearchParams(window.location.search);
    // home page
    if (homePage === null) {
      newQueryParams.delete(QUERY_PARAMS.HOME_PAGE);
    } else {
      newQueryParams.set(QUERY_PARAMS.HOME_PAGE, homePage.toString());
    }

    // price range
    if (priceRange === null) {
      newQueryParams.delete(priceRange);
    } else {
      newQueryParams.set(QUERY_PARAMS.PRICE_RANGE, JSON.stringify(priceRange));
    }
  };

  return {
    page: pageQuery ? +pageQuery : 1,
    priceRange,
    setQueryParams,
  };
};
