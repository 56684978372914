export interface WidgetConfigProps {
  divId: string;
  environmentId: string;
  type: UnitType;
  unitId?: string;
  gmapToken: string;
  defaultMargin?: boolean;
  design?: WidgetDesign;
  hideRating?: boolean;
  hideLanguageSelector?: boolean;
  clientIdentifier?: string;
  paymentDefaultData?: WidgetPaymentDefaultData;
  showSearchHotelInput?: boolean;
  languages: LANGUAGES[];
  shadowDom?: boolean;
  defaultLang?: LANGUAGES;
  locationDropdownDesign: LocationDropdownDesign;
  shadowStyles?: string;
  defaultNights?: number;
  depositPopupOnAccessPropertyPage?: boolean;
}

export interface WidgetPaymentDefaultData {
  name?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  disableInputs?: boolean;
}

export enum UnitType {
  MONOUNIT = "monounit",
  MULTIUNIT = "multiunit",
}

export enum WidgetDesign {
  DEFAULT = "default",
  HEADLESS = "headless",
}

export enum LANGUAGES {
  ES = "es",
  EN_US = "en_us",
  EN_UK = "en_uk",
  DE = "de",
  FR = "fr",
  IT = "it",
  NL = "nl",
  NO = "no",
  PL = "pl",
  PT = "pt",
  RU = "ru",
}

export enum LocationDropdownDesign {
  DEFAULT = "default",
  NESTED_DROPDOWN = "nestedDropdown",
}
