import { captureException } from "@sentry/react";
import { useCallback } from "react";

const useSentryUtils = () => {
  const captureError = useCallback(
    (
      error: any,
      additionalData?: {
        description?: string;
        [key: string]: any;
      }
    ) => {
      // const scope = new Sentry.Scope();
      const sentryAdditionalData: any = {
        errorLog: error,
        location: window.location.href,
      };
      if (error?.response?.data)
        sentryAdditionalData["responseData"] = error.response.data;
      if (error?.response?.status)
        sentryAdditionalData["responseStatus"] = error.response.status;
      if (error?.response?.headers)
        sentryAdditionalData["responseHeaders"] = error.response.headers;
      if (error?.request) sentryAdditionalData["request"] = error.request;
      if (error?.config?.url)
        sentryAdditionalData["requestUrl"] = error.config.url;
      if (error?.config?.data)
        sentryAdditionalData["requestData"] = error.config.data;
      if (additionalData)
        sentryAdditionalData["additionalData"] = additionalData;
      captureException(error, (scope) => {
        scope.setContext("additionalData", sentryAdditionalData);
        return scope;
      });
    },
    []
  );

  return {
    captureError,
  };
};

export default useSentryUtils;
