import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { DEFAULT_ADULTS } from "../../../defaultVars";
import { LANGUAGES } from "../../../hooks/languageContextHooks";
import { environmentI } from "./EnviromentSlice.type";

const initialState: environmentI = {
  username: "api@marholidays.es",
  password: "ek1VmVwXG#gvP",
  iniDate: moment().format("DD-MM-YYYY"),
  endDate: moment().add(3, "days").format("DD-MM-YYYY"),
  userLang: "",
  adults: DEFAULT_ADULTS,
  environmentDataList: [],
  showMessageRequest: false,
  serverPath: "",
  borderColor: "",
  color: "",
  childrenAgeList: [],
  zoneId: "",
  amenitiesList: [],
  hotelTypeList: [],
  currency: "€",
  error: "",
  showLastRoomMessageInfo: true,
  couponCode: "",
  couponQuantityDiscount: "",
  couponPercentDiscount: "",
  channelId: "",
  tourist: true,
  searchHotel: "",
};

export const environmentSlice = createSlice({
  name: "enviroment",
  initialState,
  reducers: {
    setEnviroment: (
      state,
      { payload }: PayloadAction<Partial<environmentI>>
    ) => {
      if (payload.adults) state.adults = payload.adults;
      if (payload.iniDate) state.iniDate = payload.iniDate;
      if (payload.endDate) state.endDate = payload.endDate;
      if (payload.serverPath) state.serverPath = payload.serverPath;
      if (payload.userLang) state.userLang = payload.userLang;
      if (payload.borderColor) state.borderColor = payload.borderColor;
      if (payload.color) state.color = payload.color;
      if (payload.childrenAgeList)
        state.childrenAgeList = payload.childrenAgeList;
      // if (payload.childrensObject)
      //   state.childrensObject = payload.childrensObject;
      if (payload.environmentDataList)
        state.environmentDataList = payload.environmentDataList;
      if (payload.zoneId !== undefined) state.zoneId = payload.zoneId;
      if (payload.amenitiesList) state.amenitiesList = payload.amenitiesList;
      if (payload.hotelTypeList) state.hotelTypeList = payload.hotelTypeList;
      if (payload.currency) state.currency = payload.currency;
      if (payload.error) {
        state.error = payload.error;
      }
      if (payload.showLastRoomMessageInfo !== undefined)
        state.showLastRoomMessageInfo = payload.showLastRoomMessageInfo;
      if (payload.channelId !== undefined) state.channelId = payload.channelId;
      if (payload.tourist !== undefined) state["tourist"] = payload.tourist;
      if (payload.roomtype) state.roomtype = payload.roomtype;
    },
    setDate: (
      state,
      {
        payload,
      }: PayloadAction<{
        iniDate: string;
        endDate: string;
      }>
    ) => {
      state.iniDate = payload.iniDate ?? state.iniDate;
      state.endDate = payload.endDate ?? state.endDate;
    },
    setAdults: (state, { payload }: PayloadAction<number>) => {
      state.adults = payload;
    },
    setCouponCode: (state, { payload }: PayloadAction<string>) => {
      state.couponCode = payload;
    },
    setSearchHotel: (state, { payload }: PayloadAction<string | undefined>) => {
      state.searchHotel = payload;
    },
  },
});

export const {
  setEnviroment,
  setDate,
  setAdults,
  setCouponCode,
  setSearchHotel,
} = environmentSlice.actions;
