import { createSelector } from "@reduxjs/toolkit";
import { State } from "../../store";

export const selectEnvironment = (state: State) => state.environment;

export const selectIniDate = createSelector(
  selectEnvironment,
  (enviroment) => enviroment.iniDate
);

export const selectEndDate = createSelector(
  selectEnvironment,
  (enviroment) => enviroment.endDate
);

export const selectSearchHotel = createSelector(
  selectEnvironment,
  (environment) => environment.searchHotel
);

export const selectChildrensAgesList = createSelector(
  selectEnvironment,
  (enviroment) => enviroment.childrenAgeList
);

export const selectAdults = createSelector(
  selectEnvironment,
  (environment) => environment.adults
);

export const selectServerPath = createSelector(
  selectEnvironment,
  (environment) => environment.serverPath
);

export const selectLang = createSelector(
  selectEnvironment,
  (environment) => environment.userLang
);

export const selectColor = createSelector(
  selectEnvironment,
  (environment) => environment.color
);

export const selectBorderColor = createSelector(
  selectEnvironment,
  (environment) => environment.borderColor
);

export const selectZoneId = createSelector(
  selectEnvironment,
  (environment) => environment.zoneId
);

export const selectAmenitiesList = createSelector(
  selectEnvironment,
  (environment) => environment.amenitiesList
);

export const selectHotelTypeList = createSelector(
  selectEnvironment,
  (environment) => environment.hotelTypeList
);

export const selectCurrency = createSelector(
  selectEnvironment,
  (environment) => environment.currency
);

export const selectEnvironmentId = createSelector(
  selectEnvironment,
  (environment) => environment.environmentDataList[0]
);

export const selectError = createSelector(
  selectEnvironment,
  (environment) => environment.error
);

export const selectShowLastRoomMessageInfo = createSelector(
  selectEnvironment,
  (environment) => environment.showLastRoomMessageInfo
);

export const selectCouponCode = createSelector(
  selectEnvironment,
  (enviroment) => enviroment.couponCode
);

export const selectChannelId = createSelector(
  selectEnvironment,
  (environment) => environment.channelId
);

export const selectIsTourist = createSelector(
  selectEnvironment,
  (environment) => environment.tourist
);
