import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import moment from "moment"
import { cartI, cartItemI, cartRoomUnitI } from "./CartSlice.type"

const storagedCartItems = () => {
  const storedCartItems = localStorage.getItem("cartState")
  if (!storedCartItems) return null
  const parsedStoredCartItem = JSON.parse(storedCartItems) as cartI
  if (
    moment(parsedStoredCartItem.localStorageTime, "DD-MM-YYYY").isBefore(
      moment(),
      "day"
    )
  ) {
    localStorage.removeItem("cartState")
    return null
  }
  return storedCartItems
}

const parsedStoragedCartItems = storagedCartItems()
  ? (JSON.parse(storagedCartItems()!) as cartI | null)
  : undefined

const initialState: cartI = {
  cartItems: parsedStoragedCartItems?.cartItems ?? [],
  totalPrice: parsedStoragedCartItems?.totalPrice ?? 0,
  checkedOutItems: [],
  localStorageTime:
    parsedStoragedCartItems?.localStorageTime ?? moment().format("DD-MM-YYYY"),
  environmentId: parsedStoragedCartItems?.environmentId
}

export const cartSlice = createSlice({
  initialState: initialState,
  name: "cart",
  reducers: {
    addCartItem: (
      state,
      { payload }: PayloadAction<cartItemI & { environmentId: string }>
    ) => {
      const { environmentId, ...restPayload } = payload
      state.environmentId = environmentId
      const cartItemToAddRoom = state.cartItems.find(
        (cartItem) => cartItem.id === restPayload.id
      )
      if (!cartItemToAddRoom) {
        state.cartItems.push(restPayload)
      } else {
        cartItemToAddRoom.rooms = [
          ...cartItemToAddRoom.rooms,
          ...restPayload.rooms
        ]
      }
      const addedPrices = restPayload.rooms.reduce((acum, room) => {
        room.units.map((units) => {
          return units.map((unit) => {
            return (acum += unit.price + unit.extrasTotalPrice)
          })
        })
        return acum
      }, 0)
      state.totalPrice = state.totalPrice + addedPrices
      localStorage.setItem("cartState", JSON.stringify(state))
    },
    setCartRoomUnits: (
      state,
      {
        payload
      }: PayloadAction<{
        hotelId: string
        roomId: string
        rooms: cartRoomUnitI[][]
        newUnitPrice: number
        validCouponCode?: string
      }>
    ) => {
      const cartItemToAdd = state.cartItems.find(
        (cartItem) => cartItem.id === payload.hotelId
      )
      const roomToAdd = cartItemToAdd!.rooms.find(
        (room) => room.id === payload.roomId
      )
      roomToAdd!.isCouponApplied = payload.validCouponCode
      roomToAdd!.units = payload.rooms
      state.totalPrice += payload.newUnitPrice
      localStorage.setItem("cartState", JSON.stringify(state))
    },
    setCartItems: (state, { payload }: PayloadAction<cartItemI[]>) => {
      state.cartItems = payload
    },
    setTotalPrice: (state, { payload }: PayloadAction<number>) => {
      state.totalPrice = payload
    },
    removeFromCart: (
      state,
      {
        payload
      }: PayloadAction<{
        hotelId: string
        roomId: string
        unitId: string
        unitPrice: number
        extrasTotalPrice: number
      }>
    ) => {
      const cartItemToRemove = state.cartItems.find(
        (cartItem) => cartItem.id === payload.hotelId
      )
      const roomToRemove = cartItemToRemove?.rooms.find(
        (room) => room.id === payload.roomId
      )

      if (roomToRemove) {
        let newRoomUnits = roomToRemove.units.map((units) =>
          units.filter((unit) => unit.id !== payload.unitId)
        )
        newRoomUnits = newRoomUnits.filter((room) => room.length > 0)
        roomToRemove.units = newRoomUnits

        if (!newRoomUnits.length) {
          const filteredRooms = cartItemToRemove!.rooms.filter(
            (room) => room.id !== roomToRemove?.id
          )
          cartItemToRemove!.rooms = filteredRooms
          if (!filteredRooms.length) {
            state.cartItems = state.cartItems.filter(
              (cartItem) => cartItem.id !== cartItemToRemove?.id
            )
          }
        }
        state.totalPrice -= payload.unitPrice + payload.extrasTotalPrice
        localStorage.setItem("cartState", JSON.stringify(state))
      }
    },
    emptyCart: (state) => {
      localStorage.removeItem("cartState")
      state.cartItems = []
      state.totalPrice = 0
    },
    setCheckedOutItems: (
      state,
      { payload }: PayloadAction<Array<cartItemI>>
    ) => {
      state.checkedOutItems = payload
    },
    updateCart: (state) => {
      const localStorageCart = localStorage.getItem("cartState")
      if (localStorageCart) {
        const parseLocalStorageCart = JSON.parse(localStorageCart) as cartI
        return { ...parseLocalStorageCart }
      }
    },
    updateCartUnitById: (state, { payload }: PayloadAction<cartRoomUnitI>) => {}
  }
})

export const {
  addCartItem,
  removeFromCart,
  emptyCart,
  setCheckedOutItems,
  setCartRoomUnits,
  updateCart,
  setCartItems,
  setTotalPrice
} = cartSlice.actions
